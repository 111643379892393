<template>
  <div :style="slidePosition" class=" z-4 w-100 h-100">
    <div
      class="position-absolute bg-summer-sky-0-75 border-warning text-warning square17"
      style="top:50%; left:5%;width:60%;padding:2.5% 2.5%;transform: translateY(-50%);"
    >
      <h6 class="text-white font-vw-3-15 font-w-600 fade17 " style="opacity:0;">
        1998
      </h6>
      <h6
        class="text-white font-vw-3-15 font-w-600 fade17 my-0"
        style="opacity:0;"
      >
        RARE ISOTOPE RENAISSANCE
      </h6>
      <p class="mt-3 text-white font-vw-1-35 text17 mb-0" style="opacity:0;">
        Following the success of TISOL, TRIUMF retooled its 520 MeV cyclotron to
        power the Isotope Separator and Accelerator (ISAC): a special facility
        for the production of rare isotope beams (RIBs). Rare isotopes are
        extremely short-lived, radioactive versions of the elements. They
        provide a window into the nature of matter and the cosmos, but you have
        to work fast, sometimes within milliseconds! Since achieving its first
        beam in 1998, TRIUMF’s online isotope separation and delivery process
        has enabled scientists to study isotopes with very short half-lives.
        Today, RIB experiments at TRIUMF involve collaborations with researchers
        from across Canada and around the world.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },

  mounted() {
    //this.startLoop()
  },
  methods: {
    slide17(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 1000
      })

      animation
        .add({
          targets: ".square17",
          opacity: [0, 1],
          translateY: ["50%", "-50%"],
          duration: 600,
          delay: 5000,
          easing: "easeInQuart"
        })
        .add({
          targets: ".fade17",

          translateY: ["1vh", "0vh"],
          opacity: [0, 1],
          duration: 1000,
          easing: "easeInOutCubic",
          delay: (el, i) => 22000 + 1200 * i
        })
        .add({
          targets: ".text17",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 1200,
          delay: 2000,
          easing: "easeInOutCubic"
        })

      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * (1 - percentage)
      )
    }
  },
  watch: {
    innerPosition() {
      this.slide17(this.innerPosition)
    }
  }
}
</script>

<style></style>
